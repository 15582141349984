import React, { Component } from "react";
import "../scss/footer.scss";

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div>© 2019 Hubert Mazur | Wszelkie prawa zastrzeżone.</div>
      </footer>
    );
  }
}
